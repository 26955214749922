//common method related tu m,utations processing
export default {
    methods: {
        mutationsInRange(mutations, start, end) {
            return mutations.filter((m) => {
                return m.variant >= start && m.variant <= end;
            });
        },

        /**
         * 
         * @param {*} mutations Array of mutatkons to filter
         * @param {*} positions Array of genomic poritions (as a single number)
         * @returns 
         */
        mutationsAtGenomicPositions(mutations, positions) {
            return mutations.filter((m) => positions.includes(m.variant));
        },

        /**
         * 
         * @param {*} mutations Array of mutatkons to filter
         * @param {*} positions Array of genomic poritions (as a single number)
         * @returns 
         */
        mutationsAtStructurePositions(mutations, structure, positions) {
            const start = structure.start;
            const end = structure.end;
            const first = structure.properties.alignment_start;

            let gPositions = [];
            positions.forEach((p) => {
                const g = this.structure2dna(p, start, end, first)
                gPositions.push(g);
                gPositions.push(g+1);
                gPositions.push(g+2)
            })

            return mutations.filter((m) => gPositions.includes(m.variant));
        },

        // this is the same code as prot2dna, renamig to avoid collisions in mixins
        structure2dna(p, start, end, first) {
            if (p < first || p > first + (end - start) / 3) return 0;
            return start + (p - first) * 3;
        },

        /**
         * Loads tab-delimited mutations file;
         * Returns array of mutations objects
         * @param {*} url 
         */
        async loadMutationsData(url) {
            if (!url || typeof url != 'string') {
                // eslint-disable-next-line no-console
                console.error(`Not a valid url: ${url}`);
                return [];
            }
            const acceptableChangesRegex = /([ARNDCQEGHILKMFPSTWYV])>([ARNDCQEGHILKMFPSTWYV])/gi; // regex to test to acceptable mutations for avg count charts

            return window.d3
                .tsv(url, function (d) {
                    // let cnt = d.aaMutationCount;

                    const cnt = (d["AA change"].match(acceptableChangesRegex) || [])
                        .length;

                    let m = {
                        variant: +d["Genome position"], //+d.variant,
                        refRegion: d["Gene/Protein name"], //d.refRegion,
                        mutationNumber: +d["Virus count for missense mutations"], //+d.mutationNumber,
                        mutationType: d["Mutation types"], //d.mutationType,
                        annotationType: d["Annotation types"], //d.annotationType,
                        alt: d["Base change:Virus count"], //d.alt,
                        proteinAminoAcids: d["AA change"], //d.proteinAminoAcids,

                        aaMutationCount: +cnt || 0,
                    };
                    return m;
                });

        },
    },


}